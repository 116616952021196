<template>
    <div class="jc-component__range">
        <div class="jc-range" :class="rangeStatus?'success':''" >
            <div class="pass" ref="pass" v-if="!rangeStatus"></div>
            <!-- <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon"></i> -->
            <i @mousedown="rangeMove">&gt;&gt;</i>
            {{rangeStatus?successText:startText}}
        </div>
    </div>
</template>

<script>
import { isPhone, messageTips } from '@/until/tools';
export default {
    props: {
        // 成功之后的函数
        successFun: {
            type: Function
        },
        //成功图标
        successIcon: {
            type: String,
            default: 'iconfont2 icon-chenggong'
        },
        //成功文字
        successText: {
            type: String,
            default: '验证成功'
        },
        //开始的图标
        startIcon: {
            type: String,
            default: 'iconfont2 icon-qianjin'
        },
        //开始的文字
        startText: {
            type: String,
            default: '请按住滑块拖到最右边'
        },
        //失败之后的函数
        errorFun: {
            type: Function
        },
        account:{
            type:String,
            default:''
        }
    },
    data(){
        return {
            disX : 0,
            rangeStatus: false
        }
    },
    methods:{
        //滑块移动
        rangeMove(e){
            if(!this.account) {
                messageTips('请输入手机号码',2);
                return false;
            }
            if(!isPhone(this.account)) {
                messageTips('手机号不正确',2);
                return false;
            }
            let ele = e.target;
            let passed = this.$refs.pass;
            let startX = e.clientX;
            let eleWidth = ele.offsetWidth;
            let parentWidth =  ele.parentElement.offsetWidth;
            let MaxX = parentWidth - eleWidth;
            if(this.rangeStatus){//不运行
                return false;
            }
            document.onmousemove = (e) => {
                let endX = e.clientX;
                this.disX = endX - startX;
                if(this.disX<=0){
                    this.disX = 0;
                }
                if(this.disX>=MaxX-eleWidth){
                    this.disX = MaxX;
                }
                
                passed.style.transition = 'none';
                passed.style.width = this.disX+'px';
                ele.style.transition = '.1s all';
                ele.style.transform = 'translateX('+this.disX+'px)';
                e.preventDefault();
            }
            document.onmouseup = ()=> {
                if(this.disX !== MaxX){
                    ele.style.transition = '.5s all';
                    ele.style.transform = 'translateX(0)';
                    this.errorFun && this.errorFun();
                    passed.style.width = 0;
                    passed.style.transition = '.5s all';
                }else{
                    this.rangeStatus = true;
                    this.successFun && this.successFun();
                }
                document.onmousemove = null;
                document.onmouseup = null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@mixin jc-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.jc-component__range{
    .jc-range{
        background-color: #f3f3f4;
        border-radius:4px;
        position: relative;
        transition: 1s all;
        user-select: none;
        color: #aaa;
        font-size: 15px;
        @include jc-flex;
        height: 40px; /*no*/
        &.success{
            background-color: #387bf6;
            color: #fff;
            i{
                color: #387bf6;
            }
        }
        i{
            position: absolute;
            left: 0;
            width: 64px;/*no*/
            height: 100%;
            color: #BBB;
            background-color: #fff;
            border: 1px solid #d8d8d8;
            cursor: pointer;
            font-size: 24px;
            // @include jc-flex;
            text-align: center;
        }
        .pass {
            position:absolute;
            top:0;
            left:0;
            height:40px;
            background:#387bf6;
        }
    }
}
</style>