<template>
	<div class="register-box">
		<div class="page-title">
			<h1>
				<div
					style="display: inline-block; cursor: pointer"
					@click="login"
				>
					<a-icon type="left" />
				</div>
				申请试用
			</h1>
			<div style="color: #666; font-size: 12px">
				提交申请后，即可使用手机验证码方式登录使用平台，试用期限为7天.
			</div>
		</div>
		<div class="form">
			<div class="form-item">
				<div class="label">手机号码</div>
				<div class="input-wrap">
					<a-input
						v-model="account"
						size="large"
						placeholder="请输入手机号码"
					/>
				</div>
			</div>
			<div class="form-item">
				<div class="label">密码</div>
				<div class="input-wrap">
					<a-input-password
						v-model="password"
						size="large"
						placeholder="请输入密码"
					/>
				</div>
			</div>
			<div class="form-item">
				<div class="label">确认密码</div>
				<div class="input-wrap">
					<a-input-password
						v-model="repassword"
						size="large"
						placeholder="请再次输入密码"
					/>
				</div>
			</div>
			<div class="form-item">
				<div class="label">真实姓名</div>
				<div class="input-wrap">
					<a-input
						v-model="realName"
						size="large"
						placeholder="请输入真实姓名"
					/>
				</div>
			</div>
			<div class="form-item">
				<div class="label">工作单位</div>
				<div class="input-wrap">
					<a-input
						v-model="organName"
						size="large"
						placeholder="请输入您的工作单位"
					/>
				</div>
			</div>
			<div class="form-item">
				<div class="label">验证码</div>
				<div v-if="!status" class="rang-wrap">
					<jc-range
						:status="status"
						:account="account"
						:key="rangeKey"
						:successFun="onMpanelSuccess"
						:errorFun="onMpanelError"
					/>
				</div>
				<a-input v-model="verifyCode" v-else class="axd">
					<a-button
						slot="addonAfter"
						size="large"
						style="
							margin-top: 0;
							height: 44px;
							width: 112px;
							border: 1px;
						"
						type="primary"
						@click="getVerifyCode"
						:disabled="buttonText !== '获取验证码'"
						>{{ buttonText }}</a-button
					>
				</a-input>
			</div>
		</div>

        <div style="padding:10px 0;">
            <a-checkbox v-model="isChecked">我已阅读</a-checkbox><a href="http://www.epsnet.com.cn/#/Agreements" target="_blank">《EPS DATA产品用户使用协议》</a>
        </div>
        <div class="btn">
            <a-button
                type="primary"
                @click="register"
                block
                size="large"
                style="font-size: 14px"
                >立即申请</a-button
            >
        </div>

		<a-modal
			v-model="successVisible"
			wrapClassName="success-win"
			title=""
			:closable="false"
			centered
			:footer="null"
		>
			<div class="content">
				<a-icon type="check-circle" theme="filled" />
				<div class="tips1">恭喜您，申请成功！</div>
				<div class="tips2">
					请用您注册使用的手机号码/密码登录，页面将于{{
						locationSeconds
					}}s后跳转登录页面
				</div>
				<div class="btns">
					<a-button type="primary" @click="login">去登录</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import JcRange from "@/components/JcRange.vue";
import { getCookie, isPhone, messageTips, setCookie } from "../../until/tools";
var timer;
var seconds;
export default {
	name: "Register",
	components: { JcRange },
	data() {
		return {
			buttonText: "获取验证码",
			account: "",
			password: "",
			repassword: "",
			verifyCode: "",
			organName: "",
			realName: "",
			successVisible: false,
			locationSeconds: 4,
			status: false,
			rangeKey: 0,
            isChecked: false,
		};
	},
	watch: {
		account() {
			this.status = false;
			this.rangeKey = Math.random();
			this.verifyCode = "";
		},
	},
	methods: {
		getVerifyCode() {
			if (this.account === "") {
				messageTips("请输入手机号码", 2);
				return false;
			}
			if (!isPhone(this.account)) {
				messageTips("手机号码格式不正确", 2);
				return false;
			}
			let params = {
				account: this.account,
			};
			this.$http.getSign(params).then((res) => {
				this.status = true;
				if (res) {
					if (res.sign) {
						this.$http
							.sendCode({ sign: res.sign, ...params })
							.then((res) => {
								this.setCountDown(59);
								// messageTips(res.message, 3);
								this.verifyCode = res.verifyCode;
							});
					} else {
						messageTips(res.message, 1);
					}
				}
			});
		},
		onMpanelSuccess() {
			if (!timer) {
				this.getVerifyCode();
			} else {
				this.status = true;
			}
		},
		onMpanelError() {},
		setCountDown(num) {
			seconds = num;
			this.buttonText = num + "s";
			timer = setInterval(() => {
				seconds -= 1;
				this.buttonText = seconds + "s";
				if (seconds < 0) {
					clearInterval(timer);
					this.buttonText = "获取验证码";
				}
			}, 1000);
		},
		register() {
			if (this.account === "") {
				messageTips("请输入手机号码", 2);
				return false;
			}
			if (!isPhone(this.account)) {
				messageTips("手机号码格式不正确", 2);
				return false;
			}
			if (this.verifyCode === "") {
				messageTips("请输入验证码", 2);
				return false;
			}
			if (this.password === "") {
				messageTips("请输入密码", 2);
				return false;
			}
			if (this.repassword === "") {
				messageTips("请输入确认密码", 2);
				return false;
			}
			if (this.repassword !== this.password) {
				messageTips("两次密码不一致", 2);
				return false;
			}
			if (this.realName === "") {
				messageTips("请输入真实姓名", 2);
				return false;
			}
			if (this.organName === "") {
				messageTips("请输入工作单位", 2);
				return false;
			}
            if (!this.isChecked) return messageTips('请仔细阅读用户使用协议',2)
			let params = {
				account: this.account,
				verifyCode: this.verifyCode,
				password: this.password,
				realName: this.realName,
				organName: this.organName,
			};
			this.$http.register(params).then((res) => {
				if (res) {
					if (res.success) {
						this.successVisible = true;
						timer = setInterval(() => {
							this.locationSeconds -= 1;
							if (this.locationSeconds < 1) {
								clearInterval(timer);
								this.$router.push("/login");
							}
						}, 1000);
					} else {
						messageTips(res.message, 1);
					}
				}
			});
		},
		login() {
			this.$router.push("/login");
		},
	},
	mounted() {
		let cookieSeconds = getCookie("seconds");
		if (cookieSeconds) {
			let num = parseInt(cookieSeconds);
			this.setCountDown(num);
		}

		window.onbeforeunload = () => {
			if (seconds > 0) {
				setCookie("seconds", seconds, { time: seconds }, true);
			}
		};
	},
	beforeDestroy() {
		if (timer) {
			clearInterval(timer);
		}
	},
};
</script>

<style lang="scss" scoped>
.register-box{
    margin:1rem auto 0px;
    background: #fff;
    position: relative;
    z-index: 99;
    width: 4.5rem;
    .page-title{
        h1{
            font-size: 22px;
        }
    }
    .form{
        height: 510px;
        overflow: auto;
        .form-item{
            .label{
                font-size: 14px;
                padding:16px 0px;
            }
            .ant-input-lg{
                font-size: 14px;
                height: 40px;
            }
            .ant-input-password{
                /deep/ .ant-input-lg{
                    font-size: 14px;
                }
            }
        }
    }
}
@media all and (max-height:763px) {
    .register-box{
        .form{
            height: 326px;
        }
    }
}

@media all and (max-height:540px) {
    .register-box{
        .form{
            height: 326px;
        }
    }
}
</style>
